import styled, {DefaultTheme} from 'styled-components';

import {media} from '@brightlive/shared/styles/breakpoints';
import T from '../../styles/typography';

const S: Record<string, React.ElementType> = {};

S.LoginWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

S.BackArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

S.Title = styled(T.ModalTitle)`
  margin-bottom: 30px;
  text-align: center;
`;

S.Subtitle = styled(T.ModalSubtitle)``;

S.SubtitleLeft = styled(T.ModalSubtitle)`
  text-align: left;
`;
S.Logo = styled.img`
  height: 65px;
`;
S.AlternateText = styled(T.Link)`
  cursor: default;
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: 400;
  font-family: ${props => props.theme.fonts.dm};
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
`;

S.InputWrapper = styled.div`
  padding-bottom: ${props => props.theme.spacing.LG};
  width: 100%;
`;

S.TextInputRow = styled.div`
  width: 100%;

  ${media.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${props => props.theme.spacing.LG};
  }
`;

S.Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.borderColor.borderSubdued};
`;

S.BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: ${props => props.theme.borderWidth[1]} solid
    ${props => props.theme.borderColor.borderSubdued};
  margin-top: ${props => props.theme.spacing['2XL']};
  padding-top: ${props => props.theme.spacing.LG};
  width: 100%;
`;

S.Terms = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.contentColor.contentDefault};
  text-align: center;
  margin-bottom: ${props => props.theme.spacing['2XL']};
  margin-top: ${props => props.theme.spacing.MD};
`;

S.Bold = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
`;

S.Link = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
  text-decoration: underline;
  color: ${props => props.theme.contentColor.contentDefault};
  cursor: pointer;
`;

S.AnchorLink = styled.a`
  font-weight: 700;
  text-decoration: underline;
  color: ${props => props.theme.contentColor.contentDefault};
  cursor: pointer;
`;

// Login page
S.LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

S.Links = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${props => props.theme.spacing['2XL']};
`;

S.Reset = styled(S.Link)`
  font-family: ${props => props.theme.fonts.dm};
  font-size: 14px;
  display: flex;
  align-items: center;
`;

S.OrWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-bottom: 20px;
`;

S.OrLine = styled.div`
  height: 1px;
  background-color: ${props => props.theme.borderColor.borderSubdued};
  width: 100%;
  position: absolute;
`;

S.OrText = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.contentColor.contentDefault};
  margin: 0 8px;
  position: relative;
`;

S.OrBackground = styled.div`
  height: 5px;
  width: 30px;
  background-color: ${props => props.theme.backgroundColor.backgroundBody};
  position: absolute;
`;

S.AltLoginButtonWrapper = styled.div(
  (props: {isCreator: boolean; theme: DefaultTheme}) => `
  margin-bottom: ${props.isCreator ? '0' : props.theme.spacing['2XL']};
  width: 100%;
`
);

// Forgot Password Page
S.ForgotInputWrapper = styled.div`
  margin: ${props => props.theme.spacing.LG} 0
    ${props => props.theme.spacing.MD};
  width: 100%;
`;

// Sign up page
S.TextInputHalf = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.LG};

  ${media.desktop} {
    width: 48%;
    margin-bottom: 0;
  }
`;

S.CheckboxWrapper = styled.div`
  width: 100%;
`;

S.TermsCheckboxWrapper = styled(S.CheckboxWrapper)`
  margin-top: ${props => props.theme.spacing.LG};
`;

S.PasswordHelpWrapper = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.theme.borderRadius.MD};
  width: 100%;
  padding: 0 ${props => props.theme.spacing.SM};
  box-sizing: border-box;
  position: relative;
  top: -${props => props.theme.spacing.SM};
  margin-bottom: 2px; // to prevent jiggle when open
`;

S.PasswordHelpTitle = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.contentColor.contentDefault};
  margin-bottom: 10px;
`;

S.PasswordHelpRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

S.PasswordHelpText = styled(S.PasswordHelpTitle)(
  (props: {theme: DefaultTheme; isFulfilled: boolean}) => `
  font-weight: 400;
  color: ${
    props.isFulfilled
      ? props.theme.contentColor.contentDefault
      : props.theme.contentColor.contentSubdued
  };
  margin-bottom: 0;
`
);

S.PasswordHelpCircle = styled.div(
  (props: {theme: DefaultTheme; isFulfilled: boolean}) => `
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: ${
    props.isFulfilled
      ? props.theme.contentColor.contentPositive
      : props.theme.contentColor
  };
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`
);

S.Checkmark = styled.img`
  width: 7px;
`;

S.ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.spacing['2XL']};
  width: 100%;
`;

// Security Page
S.SecurityContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing['3XL']};
`;

S.HelpText = styled(S.PasswordHelpText)`
  color: ${props => props.theme.contentColor.contentDefault};
  margin-bottom: ${props => props.theme.spacing.XL};
`;

S.CodeWrapper = styled.div`
  margin-top: ${props => props.theme.spacing.LG};
  margin-bottom: ${props => props.theme.spacing.MD};
  width: 100%;
`;

S.ResendText = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: ${props => props.theme.spacing['2XS']};
  color: ${props => props.theme.contentColor.contentDefault};
`;

// Success Page
S.SuccessTitle = styled(S.Title)`
  margin-bottom: ${props => props.theme.spacing.XL};
  width: 280px;
  text-align: center;
`;

// Password strength
S.PasswordStrengthContainer = styled.div`
  margin-top: ${props => props.theme.spacing['2XS']};
`;

export default S;
